import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import emision1 from "../../../static/img/emision-deudas/emision1.jpg"
import emision2 from "../../../static/img/emision-deudas/emision2.jpg"
import emision3 from "../../../static/img/emision-deudas/emision3.jpg"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Emisión de deuda"/>
            <Banner title="Emisión de deuda"/>
            <section className="about_consulting_area bg-white pt-5 pb-5">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-7 order-1 order-lg-0">
                            <div className="ex_content">
                                <h3>Perfil del cliente</h3>

                                <p>Una empresa del sector de telecomunicaciones en México ha expandido constantemente su
                                    infraestructura, a través de la contratación de créditos revolventes y sindicados,
                                    pero desea reestructurar sus créditos existentes a plazos más largos (que no
                                    impliquen una renegociación constante de condiciones), así como disminuir el costo
                                    de su deuda. Para ello, la empresa planea aprovechar el entorno imperante de tasas
                                    bajas en los mercados internacionales; por lo que decidió colocar un eurobono en
                                    dólares.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 order-0 order-lg-1 text-center">
                            <img className="video_img wow fadeInRight" src={emision1}
                                 alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about_consulting_area bg-white">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-5">
                            <img className="video_img w-100 wow fadeInLeft" src={emision2}
                                 alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h3>Mandato</h3>

                                <p>A través de un due dilligence minucioso, se ha decidido contratar la solución de
                                    Emisión de Deuda a la medida de IRStrat, con el mandato de facilitar el proceso de
                                    colocación del eurobono, acortar el tiempo de ejecución y minimizar el costo del
                                    instrumento.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 pb-0">
                <div className="container ex_content">
                    <div className="row m-0">
                        <div className="col-md-12 pl-3 pl-lg-0">
                            <h3 className="s_title d-block">Solución</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="executive_area pt-3 pb-4">
                <div className="container">
                    <div className="row step service_right_sidebar p-0 mb-5 d-flex align-items-center">
                        <div className="col-lg-7">
                            <div id="accordion7" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion service_accordion">
                                <div className="card active">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion7" href="#collapse25"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse25">
                                                <span className="pluse">+</span><span className="minus">-</span>Estrategia
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse25" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body"><p>IRStrat conduce un análisis preliminar
                                            de mercados y
                                            evalúa el alcance de emisión del cliente en los mercados internacionales de
                                            deuda, para después
                                            desarrollar un plan de acción detallado para lograr una colocación óptima.
                                            Asimismo, IRStrat
                                            prepara una agenda de trabajo que detalla los pasos precisos para completar
                                            el Prospecto del
                                            eurobono y definir su indenture.</p>
                                            <p>Nuestro equipo, de la mano del equipo directivo, comienza a desarrollar
                                                la tesis de inversión,
                                                historia y ventaja competitiva de la Compañía, así como el estilo de
                                                comunicación (mix
                                                cualitativo y cuantitativo), y una óptima estrategia de divulgación.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse26"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse26">
                                                <span className="pluse">+</span><span className="minus">-</span>Táctica
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse26" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body"><p>IRStrat conduce un entrenamiento exhaustivo del
                                            equipo ejecutivo de
                                            nuestro cliente en presentaciones de roadshow, reuniones cara a cara y foros
                                            especializados. Se
                                            trabaja a detalle el Prospecto, presentaciones a inversionistas, y se define
                                            un claro e impactante
                                            fact sheet de la tesis de inversión y las bondades del instrumento.</p>
                                            <p>IRStrat lleva a cabo el targeting y beauty contest del sindicato
                                                colocador, el targeting de
                                                inversores institucionales, el roadshow nacional e internacional y
                                                elabora un proxy del book de
                                                salida. Asimismo, se lleva a cabo un intenso lobbying con fondos de
                                                inversión y pensiones, así
                                                como presentaciones con family offices, endowments, calificadoras,
                                                medios especializados y
                                                fondos mutuos de blue chips.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse27"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Resultados
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse27" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">Nuestro cliente llevó a cabo con éxito su colocación
                                            de US$300 millones
                                            a un plazo de 7 años, con un costo de deuda 250 puntos base más competitivo
                                            a su estructura de
                                            deuda previa de créditos revolventes a corto plazo; generando ahorros
                                            substanciales a su
                                            tesorería. La composición final de su book quedó integrada por
                                            inversionistas de largo plazo, en
                                            su mayoría fondos mutuos y fondos de pensiones, con una participación mínima
                                            de fondos de
                                            cobertura y otros generadores alpha.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4 mt-lg-0">
                            <img className="img-fluid w-100 wow fadeInRight" src={emision3} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
